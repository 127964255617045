<div id="header" class="flex items-center absolute w-full mx-auto z-10 bg-[#000000] px-[24px]">
    <div class="maxContainerWidth flex w-full items-center justify-between">
        <img routerLink="" (click)="changeId(5)" class="logo cursor-pointer max-w-[109px] w-full h-full min-h-6" src="assets/images/home/logo.png" alt="PostEx Logo">
        <div class="desktopNavigation flex">
            <div class="flex-space-around whitespace-nowrap">
                <a *ngFor="let page of pageRouting" [ngClass]="{'tabactive':page.id===currentId }" (click)="changeId(page.id)"  href="javascript:void(0)" [routerLink]="page.link" class="link " [class.track]="url==page.link">{{page.title}}</a>
               
                <a routerLink="/tracking" [ngClass]="{'tabactive':currentId===4 }" (click)="changeId(4)"  class="link track flex items-center hover:text-[--sec-clr]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M11.949 2.051L10.962 3.038C11.97 4.053 12.6 5.453 12.6 7C12.6 8.48521 12.01 9.90959 10.9598 10.9598C9.90959 12.01 8.48521 12.6 7 12.6C5.51479 12.6 4.09041 12.01 3.0402 10.9598C1.99 9.90959 1.4 8.48521 1.4 7C1.4 4.144 3.535 1.792 6.3 1.449V2.863C4.312 3.199 2.8 4.921 2.8 7C2.8 8.11391 3.2425 9.1822 4.03015 9.96985C4.8178 10.7575 5.88609 11.2 7 11.2C8.11391 11.2 9.1822 10.7575 9.96985 9.96985C10.7575 9.1822 11.2 8.11391 11.2 7C11.2 5.838 10.731 4.788 9.968 4.032L8.981 5.019C9.485 5.53 9.8 6.23 9.8 7C9.8 7.74261 9.505 8.4548 8.9799 8.9799C8.4548 9.505 7.74261 9.8 7 9.8C6.25739 9.8 5.5452 9.505 5.0201 8.9799C4.495 8.4548 4.2 7.74261 4.2 7C4.2 5.698 5.096 4.613 6.3 4.298V5.796C5.88 6.041 5.6 6.482 5.6 7C5.6 7.3713 5.7475 7.7274 6.01005 7.98995C6.2726 8.2525 6.6287 8.4 7 8.4C7.3713 8.4 7.7274 8.2525 7.98995 7.98995C8.2525 7.7274 8.4 7.3713 8.4 7C8.4 6.482 8.12 6.034 7.7 5.796V0H7C6.08075 0 5.17049 0.18106 4.32122 0.532843C3.47194 0.884626 2.70026 1.40024 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85651 0.737498 10.637 2.05025 11.9497C2.70026 12.5998 3.47194 13.1154 4.32122 13.4672C5.17049 13.8189 6.08075 14 7 14C8.85651 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85651 14 7C14 5.068 13.216 3.318 11.949 2.051Z" fill="#00DF9A" />
                    </svg>
                    <span> Track Order</span>
                </a>
            </div>
            <div class="flex-space-evenly gap-3">
                <div class="">
                    <a href="https://merchant.postex.pk/login" target="_blank">
                        <button class="btn py-2 leading-[18px] font-normal text-[14px] px-4 border border-[#00DF9A] text-[#00DF9A] rounded-full min-w-[90px]" routerLink="">Login</button>
                    </a>
                </div>
                <div class="" routerLink="signup">
                    <button class="py-2 leading-[18px] font-normal text-black px-4 bg-[#00DF9A] border border-[#00DF9A] text-[#00DF9A] rounded-full min-w-[90px] hover:bg-black hover:text-[#00DF9A] font-semibold hover:duration-300	 hover:transition-all transition hover:transition-shadow">Signup</button>
                </div>

            </div>
        </div>
        <div class="mobileToggle">
            <button (click)="showModal()">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M0 0H17V2.33333H0V0ZM0 5.83333H17V8.16667H0V5.83333ZM0 11.6667H17V14H0V11.6667Z" fill="white" />
                </svg>
            </button>
        </div>
    </div>
</div>
<div class="mx-5 z-[100] mobileNavigationWrap lg:hidden" [ngClass]="mobileModal == true ? 'show' : 'hide'">
    <div class="contentWrap">
        <div class="logo">
            <img (click)="mobileModal=false" routerLink="" class="logo cursor-pointer" src="assets/images/home/logo.png" alt="PostEx Logo">
        </div>
        <div class="menuWrap">
            <ul>
                <li *ngFor="let page of pageRouting">
                    <a (click)="mobileModal=false" href="javascript:void(0)" [routerLink]="page.link" class="link" [class.track]="url==page.link">{{page.title}}</a>
                </li>
                <!-- <li>
                    <a href="javascript:void(0)" routerLink="cod" class="link">COD</a>
                </li>
                <li>
                    <a href="javascript:void(0)" routerLink="cod/v1" class="link">COD V1</a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="link" routerLink="payment-gateway">Payment Gateway</a>
                </li>
                <li>
                    <a href="javascript:void(0)" class="link" routerLink="business-account">Business Account</a>
                </li>
                <li>
                    <a routerLink="finance" class="link">Finance</a>
                </li> -->
                <li>
                    <a (click)="mobileModal=false" routerLink="/tracking" class="link track flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <path d="M11.949 2.051L10.962 3.038C11.97 4.053 12.6 5.453 12.6 7C12.6 8.48521 12.01 9.90959 10.9598 10.9598C9.90959 12.01 8.48521 12.6 7 12.6C5.51479 12.6 4.09041 12.01 3.0402 10.9598C1.99 9.90959 1.4 8.48521 1.4 7C1.4 4.144 3.535 1.792 6.3 1.449V2.863C4.312 3.199 2.8 4.921 2.8 7C2.8 8.11391 3.2425 9.1822 4.03015 9.96985C4.8178 10.7575 5.88609 11.2 7 11.2C8.11391 11.2 9.1822 10.7575 9.96985 9.96985C10.7575 9.1822 11.2 8.11391 11.2 7C11.2 5.838 10.731 4.788 9.968 4.032L8.981 5.019C9.485 5.53 9.8 6.23 9.8 7C9.8 7.74261 9.505 8.4548 8.9799 8.9799C8.4548 9.505 7.74261 9.8 7 9.8C6.25739 9.8 5.5452 9.505 5.0201 8.9799C4.495 8.4548 4.2 7.74261 4.2 7C4.2 5.698 5.096 4.613 6.3 4.298V5.796C5.88 6.041 5.6 6.482 5.6 7C5.6 7.3713 5.7475 7.7274 6.01005 7.98995C6.2726 8.2525 6.6287 8.4 7 8.4C7.3713 8.4 7.7274 8.2525 7.98995 7.98995C8.2525 7.7274 8.4 7.3713 8.4 7C8.4 6.482 8.12 6.034 7.7 5.796V0H7C6.08075 0 5.17049 0.18106 4.32122 0.532843C3.47194 0.884626 2.70026 1.40024 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85651 0.737498 10.637 2.05025 11.9497C2.70026 12.5998 3.47194 13.1154 4.32122 13.4672C5.17049 13.8189 6.08075 14 7 14C8.85651 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85651 14 7C14 5.068 13.216 3.318 11.949 2.051Z" fill="#00DF9A" />
                        </svg>
                        <span> Track Order</span>
                    </a>
                </li>
            </ul>





        </div>
        <div class="footerBtn">
            <div class="flex-space-evenly gap-3">
                <!-- <div class="button-border">
                    <a (click)="mobileModal=false" href="https://merchant.postex.pk/login" target="_blank">
                        <button class="button min-w-[90px] font-semibold" routerLink="">Login</button>
                    </a>
                </div>
                <div (click)="mobileModal=false" class="button-border" routerLink="signup">
                    <button class="button min-w-[90px] font-semibold">Signup</button>
                </div> -->
                     <div >
                    <!-- <a href="https://merchant.postex.pk/login" target="_blank"> -->
                        <button (click)="redirectToLogin()" class="btn py-2 leading-[18px] font-normal text-[14px] px-4 border border-[#00DF9A] text-[#00DF9A] rounded-full min-w-[130px]" routerLink="">Login</button>
                    <!-- </a> -->
                </div>
                <div (click)="mobileModal=false" routerLink="signup">
                    <button class="py-2 leading-[18px] font-normal text-black px-4 bg-[#00DF9A] border border-[#00DF9A] text-[#00DF9A] rounded-full min-w-[130px] hover:bg-black hover:text-[#00DF9A] font-semibold hover:duration-300	 hover:transition-all transition hover:transition-shadow">Signup</button>
                </div>

            </div>
        </div>
        <div class="closeBtn">
            <button (click)="showModal()">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path d="M9 1L1 9" stroke="#00DF9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1 1L9 9" stroke="#00DF9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        </div>
    </div>

</div>
<div class="modalOverlay" *ngIf="mobileModal" (click)="showModal()">

</div>