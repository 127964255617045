import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  mobileModal: boolean = false;
  currentIndex:number=0;
  currentId:any;
  pageRouting: Array<{ title: string, link: string,id:number }> = [
    { title: "COD", link: "/cod",id:0 },
    // { title: "COD V1", link: "/cod/v1" },
    // { title: "Payment Gateway", link: "/payment-gateway" },
    { title: "Business Suite", link: "/business-suite",id:1 },
    { title: "Financing", link: "/finance",id:2 },
  ]
  url: string = "";
  constructor(private router: Router ) {
    this.url = window.location.pathname;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.url = val.url;
          // this.showModal()
        }
      })
    }, 2000)
  }
  changeId(id:number){
   
    this.currentId=id;
    
  }
  ngOnInit(): void {
  }
  redirectToLogin(): void {
    this.mobileModal = false;
    window.open('https://oms.postex.ae/auth/login', '_blank');
  }


  showModal() {
    this.mobileModal = !this.mobileModal
  }
  setActiveIndex(index: number) {
    this.currentIndex = index;
  }
}
