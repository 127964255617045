<div id="footer" [@leave]="fadeOutOnLeave" [@enter]="fadeInUpOnEnter">
    <div class="maxContainerWidth p-0">
        <div class="mt-4 md:mt-16 :md:mb-24"></div>
        <div class="flex md:justify-between flex-col md:flex-row gap-4 md:gap-2.5  ">
            <div class="md:w-4/12">
                <img class=" md:m-auto md:mx-0 md-my-0 md:mb-4 w-[81px] md:w-[125px]" src="assets/images/footer-logo.png" alt="PostEx Logo">
                <p class="copy-right-description text-md flex address mt-4">All rights reserved, the PostEx. logo and trademarks may not be used or reproduced consent of the owner.</p>
                <p class="copy-right-description text-[12px] leading-[18px] text-md hidden md:flex address mt-5">© 2024, PostEx.</p>
            </div>

            <div class="md:w-4/12 md:max-w-[350px] items-baseline justify-evenly lg:items-start flex flex-col gap-4 md:px-[15px]">
                <div class="flex gap-8 ps-1 hidden md:flex">
                    <span class="cursor-pointer">
                        <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0076 1H9.00552C7.67853 1 6.40588 1.53068 5.46755 2.47529C4.52922 3.41991 4.00207 4.70108 4.00207 6.03697V9.05915H1V13.0887H4.00207V21.1479H8.00483V13.0887H11.0069L12.0076 9.05915H8.00483V6.03697C8.00483 5.76979 8.11026 5.51356 8.29793 5.32463C8.4856 5.13571 8.74012 5.02957 9.00552 5.02957H12.0076V1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>

                    <span class="cursor-pointer">
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.0381 1H6.03115C3.26782 1 1.02769 3.25513 1.02769 6.03697V16.1109C1.02769 18.8927 3.26782 21.1479 6.03115 21.1479H16.0381C18.8014 21.1479 21.0415 18.8927 21.0415 16.1109V6.03697C21.0415 3.25513 18.8014 1 16.0381 1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.0373 10.4395C15.1608 11.2779 15.0186 12.1341 14.6308 12.8865C14.243 13.6388 13.6295 14.2489 12.8774 14.6299C12.1254 15.011 11.2732 15.1436 10.4419 15.009C9.61069 14.8743 8.84279 14.4792 8.24746 13.8799C7.65213 13.2806 7.25967 12.5075 7.12591 11.6707C6.99216 10.8339 7.12391 9.97598 7.50243 9.2189C7.88096 8.46182 8.48697 7.84418 9.23429 7.45381C9.9816 7.06345 10.8322 6.92024 11.665 7.04457C12.5145 7.17138 13.301 7.56989 13.9082 8.18122C14.5155 8.79255 14.9113 9.58428 15.0373 10.4395Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.5384 5.5332H16.5484" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </span>
                    <span class="cursor-pointer">
                        <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.0733 1.00769C22.115 1.68816 21.054 2.20861 19.9311 2.549C19.3285 1.85139 18.5275 1.35694 17.6366 1.13253C16.7457 0.908118 15.8078 0.964568 14.9498 1.29425C14.0918 1.62392 13.355 2.21092 12.8392 2.97585C12.3234 3.74078 12.0534 4.64673 12.0657 5.57118V6.57858C10.3071 6.62448 8.56456 6.23184 6.99321 5.43563C5.42186 4.63942 4.0705 3.46435 3.05949 2.01508C3.05949 2.01508 -0.943276 11.0816 8.06294 15.1112C6.00204 16.5195 3.54698 17.2256 1.05811 17.126C10.0643 22.163 21.0719 17.126 21.0719 5.54096C21.071 5.26035 21.0442 4.98044 20.9919 4.70482C22.0132 3.69087 22.7339 2.4107 23.0733 1.00769V1.00769Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </span>

                    <span class="cursor-pointer">
                        <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.6544 3.45254C22.5356 2.97444 22.2935 2.53639 21.9526 2.18263C21.6117 1.82886 21.1842 1.57192 20.7131 1.43775C18.9919 1.01465 12.1072 1.01465 12.1072 1.01465C12.1072 1.01465 5.22242 1.01465 3.50123 1.47805C3.03015 1.61222 2.60259 1.86916 2.26172 2.22292C1.92085 2.57668 1.67876 3.01474 1.55989 3.49284C1.24489 5.2513 1.0908 7.03515 1.09957 8.82195C1.08834 10.6222 1.24244 12.4197 1.55989 14.1914C1.69094 14.6546 1.93846 15.076 2.27853 15.4148C2.6186 15.7536 3.03973 15.9984 3.50123 16.1256C5.22242 16.589 12.1072 16.589 12.1072 16.589C12.1072 16.589 18.9919 16.589 20.7131 16.1256C21.1842 15.9914 21.6117 15.7344 21.9526 15.3807C22.2935 15.0269 22.5356 14.5889 22.6544 14.1108C22.967 12.3655 23.1211 10.5953 23.1148 8.82195C23.126 7.02169 22.9719 5.22424 22.6544 3.45254V3.45254Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.85553 12.1162L15.6095 8.82201L9.85553 5.52783V12.1162Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                </div>
                <div class="flex gap-4 mt-2.5 items-center">
                    <svg class="svg-icon" viewBox="0 0 24 25" fill="" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_998_386)">
                            <path class="stroke-white" d="M15.05 5.14795C16.0267 5.33851 16.9244 5.81621 17.6281 6.51988C18.3317 7.22356 18.8094 8.12121 19 9.09795M15.05 1.14795C17.0793 1.37339 18.9716 2.28212 20.4162 3.72496C21.8609 5.16779 22.772 7.05896 23 9.08795M22 17.0679V20.0679C22.0011 20.3465 21.9441 20.6221 21.8325 20.8773C21.7209 21.1325 21.5573 21.3615 21.3521 21.5498C21.1468 21.7381 20.9046 21.8814 20.6407 21.9707C20.3769 22.0599 20.0974 22.093 19.82 22.0679C16.7428 21.7336 13.787 20.6821 11.19 18.9979C8.77382 17.4626 6.72533 15.4141 5.18999 12.9979C3.49997 10.3892 2.44824 7.41894 2.11999 4.32795C2.095 4.05142 2.12787 3.77271 2.21649 3.50957C2.30512 3.24644 2.44756 3.00464 2.63476 2.79957C2.82196 2.5945 3.0498 2.43066 3.30379 2.31847C3.55777 2.20628 3.83233 2.14821 4.10999 2.14795H7.10999C7.5953 2.14317 8.06579 2.31503 8.43376 2.63148C8.80173 2.94794 9.04207 3.3874 9.10999 3.86795C9.23662 4.82802 9.47144 5.77068 9.80999 6.67795C9.94454 7.03587 9.97366 7.42486 9.8939 7.79883C9.81415 8.1728 9.62886 8.51606 9.35999 8.78795L8.08999 10.0579C9.51355 12.5615 11.5864 14.6344 14.09 16.0579L15.36 14.7879C15.6319 14.5191 15.9751 14.3338 16.3491 14.254C16.7231 14.1743 17.1121 14.2034 17.47 14.3379C18.3773 14.6765 19.3199 14.9113 20.28 15.0379C20.7658 15.1065 21.2094 15.3512 21.5265 15.7254C21.8437 16.0997 22.0122 16.5775 22 17.0679Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_998_386">
                                <rect width="24" height="24" fill="" transform="translate(0 0.147949)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <span class="text-md leading-5 address">(042) 32157777</span>

                </div>

                <div class="flex gap-4">
                    <svg class="svg-map-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3167_1941)">
                            <path d="M15.75 7.5C15.75 12.75 9 17.25 9 17.25C9 17.25 2.25 12.75 2.25 7.5C2.25 5.70979 2.96116 3.9929 4.22703 2.72703C5.4929 1.46116 7.20979 0.75 9 0.75C10.7902 0.75 12.5071 1.46116 13.773 2.72703C15.0388 3.9929 15.75 5.70979 15.75 7.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3167_1941">
                                <rect width="18" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>



                    <span class="address text-md leading-5">4th floor, CCA, 14, Sector C Phase 5 D.H.A, Lahore, Punjab</span>
                </div>
                <div class="flex gap-8 ps-1 flex md:hidden">
                    <span class="cursor-pointer">
                        <svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0076 1H9.00552C7.67853 1 6.40588 1.53068 5.46755 2.47529C4.52922 3.41991 4.00207 4.70108 4.00207 6.03697V9.05915H1V13.0887H4.00207V21.1479H8.00483V13.0887H11.0069L12.0076 9.05915H8.00483V6.03697C8.00483 5.76979 8.11026 5.51356 8.29793 5.32463C8.4856 5.13571 8.74012 5.02957 9.00552 5.02957H12.0076V1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>

                    <span class="cursor-pointer">
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.0381 1H6.03115C3.26782 1 1.02769 3.25513 1.02769 6.03697V16.1109C1.02769 18.8927 3.26782 21.1479 6.03115 21.1479H16.0381C18.8014 21.1479 21.0415 18.8927 21.0415 16.1109V6.03697C21.0415 3.25513 18.8014 1 16.0381 1Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.0373 10.4395C15.1608 11.2779 15.0186 12.1341 14.6308 12.8865C14.243 13.6388 13.6295 14.2489 12.8774 14.6299C12.1254 15.011 11.2732 15.1436 10.4419 15.009C9.61069 14.8743 8.84279 14.4792 8.24746 13.8799C7.65213 13.2806 7.25967 12.5075 7.12591 11.6707C6.99216 10.8339 7.12391 9.97598 7.50243 9.2189C7.88096 8.46182 8.48697 7.84418 9.23429 7.45381C9.9816 7.06345 10.8322 6.92024 11.665 7.04457C12.5145 7.17138 13.301 7.56989 13.9082 8.18122C14.5155 8.79255 14.9113 9.58428 15.0373 10.4395Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16.5384 5.5332H16.5484" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </span>
                    <span class="cursor-pointer">
                        <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.0733 1.00769C22.115 1.68816 21.054 2.20861 19.9311 2.549C19.3285 1.85139 18.5275 1.35694 17.6366 1.13253C16.7457 0.908118 15.8078 0.964568 14.9498 1.29425C14.0918 1.62392 13.355 2.21092 12.8392 2.97585C12.3234 3.74078 12.0534 4.64673 12.0657 5.57118V6.57858C10.3071 6.62448 8.56456 6.23184 6.99321 5.43563C5.42186 4.63942 4.0705 3.46435 3.05949 2.01508C3.05949 2.01508 -0.943276 11.0816 8.06294 15.1112C6.00204 16.5195 3.54698 17.2256 1.05811 17.126C10.0643 22.163 21.0719 17.126 21.0719 5.54096C21.071 5.26035 21.0442 4.98044 20.9919 4.70482C22.0132 3.69087 22.7339 2.4107 23.0733 1.00769V1.00769Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </span>

                    <span class="cursor-pointer">
                        <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.6544 3.45254C22.5356 2.97444 22.2935 2.53639 21.9526 2.18263C21.6117 1.82886 21.1842 1.57192 20.7131 1.43775C18.9919 1.01465 12.1072 1.01465 12.1072 1.01465C12.1072 1.01465 5.22242 1.01465 3.50123 1.47805C3.03015 1.61222 2.60259 1.86916 2.26172 2.22292C1.92085 2.57668 1.67876 3.01474 1.55989 3.49284C1.24489 5.2513 1.0908 7.03515 1.09957 8.82195C1.08834 10.6222 1.24244 12.4197 1.55989 14.1914C1.69094 14.6546 1.93846 15.076 2.27853 15.4148C2.6186 15.7536 3.03973 15.9984 3.50123 16.1256C5.22242 16.589 12.1072 16.589 12.1072 16.589C12.1072 16.589 18.9919 16.589 20.7131 16.1256C21.1842 15.9914 21.6117 15.7344 21.9526 15.3807C22.2935 15.0269 22.5356 14.5889 22.6544 14.1108C22.967 12.3655 23.1211 10.5953 23.1148 8.82195C23.126 7.02169 22.9719 5.22424 22.6544 3.45254V3.45254Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.85553 12.1162L15.6095 8.82201L9.85553 5.52783V12.1162Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <!-- <div class="mobileSocialWrap lg:hidden">
            <div class="socialIcons flex justify-center items-center">
                <div class="icon ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="19" viewBox="0 0 12 19" fill="none">
                        <path d="M10.5345 1H7.93418C6.78477 1 5.68244 1.45629 4.86968 2.26848C4.05692 3.08068 3.60032 4.18225 3.60032 5.33087V7.9294H1V11.3941H3.60032V18.3235H7.06741V11.3941H9.66773L10.5345 7.9294H7.06741V5.33087C7.06741 5.10115 7.15873 4.88084 7.32128 4.7184C7.48383 4.55596 7.7043 4.4647 7.93418 4.4647H10.5345V1Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
                        <path d="M19.878 0.866437C19.0479 1.45152 18.1289 1.89901 17.1563 2.19168C16.6343 1.59187 15.9405 1.16673 15.1688 0.973779C14.3971 0.780825 13.5848 0.829361 12.8416 1.11282C12.0984 1.39629 11.4602 1.901 11.0135 2.5587C10.5667 3.2164 10.3328 3.99535 10.3435 4.79021V5.65638C8.82022 5.69585 7.31086 5.35826 5.9498 4.67366C4.58873 3.98907 3.41822 2.97872 2.54251 1.73261C2.54251 1.73261 -0.924586 9.52818 6.87637 12.9929C5.09128 14.2038 2.96476 14.8109 0.80896 14.7252C8.60992 19.0561 18.1444 14.7252 18.1444 4.76422C18.1436 4.52295 18.1204 4.28228 18.0751 4.0453C18.9597 3.17349 19.584 2.07277 19.878 0.866437V0.866437Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="19" viewBox="0 0 12 19" fill="none">
                        <path d="M10.5345 1H7.93418C6.78477 1 5.68244 1.45629 4.86968 2.26848C4.05692 3.08068 3.60032 4.18225 3.60032 5.33087V7.9294H1V11.3941H3.60032V18.3235H7.06741V11.3941H9.66773L10.5345 7.9294H7.06741V5.33087C7.06741 5.10115 7.15873 4.88084 7.32128 4.7184C7.48383 4.55596 7.7043 4.4647 7.93418 4.4647H10.5345V1Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
                        <path d="M19.878 0.866437C19.0479 1.45152 18.1289 1.89901 17.1563 2.19168C16.6343 1.59187 15.9405 1.16673 15.1688 0.973779C14.3971 0.780825 13.5848 0.829361 12.8416 1.11282C12.0984 1.39629 11.4602 1.901 11.0135 2.5587C10.5667 3.2164 10.3328 3.99535 10.3435 4.79021V5.65638C8.82022 5.69585 7.31086 5.35826 5.9498 4.67366C4.58873 3.98907 3.41822 2.97872 2.54251 1.73261C2.54251 1.73261 -0.924586 9.52818 6.87637 12.9929C5.09128 14.2038 2.96476 14.8109 0.80896 14.7252C8.60992 19.0561 18.1444 14.7252 18.1444 4.76422C18.1436 4.52295 18.1204 4.28228 18.0751 4.0453C18.9597 3.17349 19.584 2.07277 19.878 0.866437V0.866437Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            </div>
            <div class="copyright">
                © PostEx. 2022, all rights reserved wamo & the PostEx. logo are trademarks and may not be used or reproduced consent of the owner.
            </div>
        </div> -->
    </div>
    <p class="copy-right-description text-md hidden mt-8">© PostEx. 2024, all rights reserved, the PostEx. logo and trademarks may not be used or reproduced consent of the owner.</p>
    <!-- <p class="copy-right-description text-md flex md:hidden address">PostEx is a duly registered Non-Banking Financial Institution (NBFI), recognized and licensed by SECP Pakistan.</p> -->

</div>