import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path:"", loadChildren:()=>import("./pages/home/home.module").then(m=>m.HomeModule) },
  { path:"payment-gateway", loadChildren:()=>import("./pages/payment-gateway/payment-gateway.module").then(m=>m.PaymentGatewayModule) },
  { path:"business-suite", loadChildren:()=>import("./pages/business-account/business-account.module").then(m=>m.BusinessAccountModule) },
  { path:"signup", loadChildren:()=>import("./pages/signup/signup.module").then(m=>m.SignupModule) },
  { path:"tracking", loadChildren:()=>import("./pages/tracking/tracking.module").then(m=>m.TrackingModule) },
  { path: "cod", loadChildren: () => import("./pages/cod/cod.module").then(m => m.CODModule) },
  { path:"finance", loadChildren:()=>import("./pages/finance/finance.module").then(m=>m.FinanceModule) },
  { path:"case-study-page", loadChildren:()=>import("./pages/case-study-page/case-study-page.module").then(m=>m.CaseStudyPageModule) },
  { path:"blog-page", loadChildren:()=>import("./pages/blog-page/blog-page.module").then(m=>m.BlogPageModule) },
  { path:"terms-conditions", loadChildren:()=>import("./pages/terms-condition/terms-condition.module").then(m=>m.TermsConditionModule) },
  { path:"team", loadChildren:()=>import("./pages/team/team.module").then(m=>m.TeamModule) },
  { path:"faqs", loadChildren:()=>import("./pages/faq/faq.module").then(m=>m.FaqModule) },
  { path:"case-studies", loadChildren:()=>import("./pages/case-stuides/case-stuides.module").then(m=>m.CaseStuidesModule) },
  { path:"blog-list", loadChildren:()=>import("./pages/blog-list/blog-list.module").then(m=>m.BlogListModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

